$(document).ready(function() {

    let login = $('.login');

    login.popover({
        container: 'body',
        html: true,
        placement: 'bottom',
        sanitize: false,
        content: function()
        {
            return $('.login-popover').html().replace('id="remember_panel_placeholder"', 'id="remember_panel"');
        }
    });

    login.on('shown.bs.popover', function() {
        $('.popover:visible').first().css('width', $('.popover:visible').first().width());
        $('.popover:visible').first().find('input:visible[type="text"]').first().focus();
    });

    $(document).on('click', '.nav-link.login', function(e) {
        if ($(window).width() >= 992) {
            e.preventDefault();
        }
    });


    $('body').on('click', function (e)
    {
        $('[data-bs-toggle=popover]').each(function () {

            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });


    $(document).on('submit', '#login-panel-form', function(e) {
        e.preventDefault();

        var $this = $(this);
        var $btn = $(this).find('#login-panel-submit-btn');
        var loadingText = $('#login-panel-submit-btn').data('loading-text');

        if ($btn.html() !== loadingText) {
            $btn.data('original-text', $btn.html());
            $btn.html(loadingText);
        }

        var username = $(this).find('input[name="username_panel"]').val();
        var password = $(this).find('input[name="password_panel"]').val();
        var remember = $(this).find('input[name="remember_panel"]').val();
        var token = $(this).find('input[name="_token"]').val();

        $(document)
            .find('#login-panel-form span.invalid-feedback')
            .remove();

        $.ajax({
            type: 'post',
            url: appUrl() + '/login',
            cache: false,
            data: {
                username: username,
                password: password,
                remember: remember,
                token: token
            },
            success: function(data) {
                // location.reload();
                window.location.href = $this.data('r');
            },
            error: function(data) {

                switch (data.status) {
                    case 419:
                        $(document)
                            .find('#login-panel-form input[name="username_panel"]')
                            .parent()
                            .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>Ihre Session ist abgelaufen. Bitte laden Sie die Seite neu.</strong></span>');
                        break;
                    default:
                        $btn.html($btn.data('original-text'));

                        if (data.responseJSON.errors.username) {

                            $(document)
                                .find('#login-panel-form input[name="username_panel"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.username[0]+'</strong></span>');
                        }

                        if (data.responseJSON.errors.password) {

                            $(document)
                                .find('#login-panel-form input[name="password_panel"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.password[0]+'</strong></span>');
                        }

                        if (data.responseJSON.errors.is_active) {

                            $(document)
                                .find('#login-panel-form input[name="username_panel"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.is_active[0]+'</strong></span>');
                        }

                }
            }
        });
    });

});
