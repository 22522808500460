$(document).ready(function ()
{
    var last_move = null;
    var last_touch = null;

    var track = {};
        track.touch_counter = 0;
        track.focus_counter = 0;
        track.blur_counter = 0;
        track.changed_after_blur_counter = 0;
        track.checked_counter = 0;
        track.selected_counter = 0;
        track.move_counter = 0;
        track.key_counter = 0;
        track.click_counter = 0;
        track.direction_changes_x = 0;
        track.direction_changes_y = 0;
        track.direction_x = null;
        track.direction_y = null;
        track.last_key = null;
        track.last_move = null;
        track.last_touches = [];
        track.last_moves = [];
        track.last_keys = [];
        track.acceleration = [];
        track.start = new Date();

    var check = {};
        check.keys = 0;
        check.moves = 0;
        check.moves_x = 0;
        check.moves_y = 0;
        check.focus = 0;
        check.blur = 0;
        check.touches = 0;
        check.clicked = 0;
        check.checked = 0;
        check.changed_field = 0;
        check.selected = 0;
        check.time_passed  = 0;

    var is_human = false;
    var selector = $('#silcap_unique_id');
    var label    = selector.parent('label');
    var form = selector.parents('form').first();
    var elements = {};

    if(form.length > 0)
    {
        $(document).on('focus', 'input[type="text"], input[type="email"], textarea', function (e) {
            var el = $(this);
            elements[el.attr('id')] = el.val();
            track.focus_counter++;
        });

        $(document).on('click', 'input[type="submit"], button[type="submit"]', function (e) {
            var el = $(this);
            track.click_counter++;
        });

        $(document).on('blur', 'input[type="text"], input[type="email"], textarea', function (e) {
            track.blur_counter++;
            var el = $(this);
            if(el.val() != elements[el.attr('id')])
            {
                track.changed_after_blur_counter++;
            }

        });
        $(document).on('click', 'input[type="checkbox"], input[type="radio"]', function (e)
        {
            track.checked_counter ++;
        });
        $(document).on('select', 'select', function (e)
        {
            track.selected_counter ++;
        });

        $(document).on('touchstart', function (e) {
            trackMovement(e)
        });

        $(document).on('mousemove', function (e) {
            trackMovement(e);
        });

        $(document).on('keypress', function (e) {
            evt = e || window.event;
            var charCode = evt.keyCode || evt.which;
            if (!is_human)
            {
                track.last_key = String.fromCharCode(charCode);
                track.key_counter++;
                track.last_keys.push({d: new Date()})
                checkHuman();
            }
        });

        function trackMovement(e)
        {
            if(e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel')
            {
                var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
                var posX = touch.pageX;
                var posY = touch.pageY;
                var touched = true;

            } else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove' || e.type === 'mouseover'|| e.type==='mouseout' || e.type==='mouseenter' || e.type==='mouseleave') {
                var posX = e.clientX;
                var posY = e.clientY;
                var touched = false;
            }

            if (!is_human) {
                if (track.last_moves.length > 2) {
                    let i = track.last_moves.length - 1;
                    let last_d = track.direction_x;

                    if (posX - track.last_moves[i].x > 0) {
                        track.direction_x = 'right';
                    } else if (posX - track.last_moves[i].x === 0) {
                        track.direction_x = 'same';
                    } else {
                        track.direction_x = 'left';
                    }

                    track.acceleration.push((Math.abs(posY - track.last_moves[i].y) + Math.abs(posX - track.last_moves[i].x)) / 2);

                    if (track.direction_x !== 'same') {
                        track.direction_changes_x++;
                    }

                    last_d = track.direction_y;

                    if (posY - track.last_moves[i].y > 0) {
                        track.direction_y = 'down';
                    } else if (posY - track.last_moves[i].y === 0) {
                        track.direction_y = 'same';
                    } else {
                        track.direction_y = 'up';
                    }
                    if (track.direction_y !== 'same') {
                        track.direction_changes_y++;
                    }
                }
                if(touched === true)
                {
                    track.last_touch = {x: posX, y: posY};
                    track.last_touches.push({x: posX, y: posY, d: new Date()});
                    track.touch_counter++;
                }
                else{
                    track.last_move = {x: posX, y: posY};
                    track.last_moves.push({x: posX, y: posY});
                    track.move_counter++;
                }
                checkHuman();
            }
        }

        function checkHuman()
        {
           let now = new Date();

            // evaluate key usage
            if (track.key_counter > 10)
            {
                let spans = [];
                track.last_keys.forEach(function (currentValue, index, arr) {
                    if (track.last_keys[index + 1] !== undefined) {
                        let span = track.last_keys[index + 1].d - currentValue.d;
                        spans.push(span);
                    }
                });

                let t = statistics(spans);
                if (Math.floor(t.d) > 150)
                {
                    check.keys = 1;
                }
            }

            // evaluate mouse

            if ((track.direction_changes_x + track.direction_changes_y) / 2 > 300)
            {
                check.moves = 1;
            }
            if (track.direction_changes_x  > 20)
            {

                let movx = [];
                track.last_moves.forEach(function (currentValue, index, arr)
                {
                        movx.push(currentValue.x);
                });

                let t = statistics(movx);

                if (Math.floor(t.d) > 170)
                {
                    check.moves_x = 1;
                }
            }
            if (track.direction_changes_y  > 20)
            {
                let movy = [];
                track.last_moves.forEach(function (currentValue, index, arr)
                {
                    movy.push(currentValue.y);
                });

                let t = statistics(movy);

                if (Math.floor(t.d) > 170)
                {
                    check.moves_y = 1;
                }
            }

            // evaluate touch
            if(track.touch_counter > 8)
            {
                check.touches = 1;
            }

            if(track.touch_counter > 1)
            {
                let spans = [];
                track.last_touches.forEach(function (currentValue, index, arr)
                {
                    if (track.last_touches[index + 1] !== undefined) {
                        let span = track.last_touches[index + 1].d - currentValue.d;
                        spans.push(span);
                    }
                });

                let t = statistics(spans);

                if (Math.floor(t.d) > 300)
                {
                    check.touches = 1;
                }
            }

            // evaluate field usage

            if (track.changed_after_blur_counter > (form.find('input[type="text"]:visible, input[type="email"]:visible, textarea:visible').length / 3))
            {
                check.changed_field = 1;
            }

            if (track.blur_counter > (form.find('input[type="text"]:visible, input[type="email"]:visible, textarea:visible').length / 3))
            {
                check.blur = 1;
            }
            if (track.focus_counter > (form.find('input[type="text"]:visible, input[type="email"]:visible, textarea:visible').length / 3))
            {
                check.focus = 1;
            }
            if (track.checked_counter > (form.find('input[type="radio"]:visible, input[type="checkbox"]:visible').length / 3))
            {
                check.checked = 1;
            }
            if (track.selected_counter > (form.find('select:visible').length / 3))
            {
                check.selected = 1;
            }
            if (track.click_counter == 1)
            {
                check.clicked = 1;
            }

            if(now - track.start > 2500)
            {
                check.time_passed = 0.5;
            }

            var points = 0;
            for(z in check)
            {
                points += check[z];
            }

            label.attr('data-evl', points*10);
            if (points*10 >= label.data('sclv'))
            {
                is_human = true;

                label.attr('data-evl', 1000);
            }
        }

        function fillSilentCaptcha()
        {
            checkHuman();
            if (!is_human) {
                return false;
            }

            var t = selector,
                l = t.parent(),
                d = l.data();

            var parts = d.context.split('-');

            var rand = parseInt(parts[0]);
            var day = parseInt(parts[1]) / 8;
            var my = parseInt(parts[2]) / 9;
            var solve = rot13(base64_encode((rand * day) - my));

            $('#silcap_unique_id').val(solve).attr('name', 'silcap_value');
        }

        function statistics(a)
        {
            var n = a.length;
            var sum = 0;

            for (var i = 0; i < n; i++){
                sum += a[i];
            }
            var mean = sum / n;
            var sqDiff = 0;

            for (var i = 0; i < n; i++) {
                sqDiff += (a[i] - mean) * (a[i] - mean);
            }

            var variance = sqDiff / n;
            var deviation = Math.sqrt(variance);

            return {m: mean, v: variance, d: deviation, n: n};
        }

        function setFocus(element) {
            element.tabIndex = -1;
            element.focus();
        }

        form.on('click', '.silcap-submit', function (e)
        {
            setFocus(form);
            fillSilentCaptcha();
        });

        form.on('submit', function (e)
        {
            setFocus(form);
            e.preventDefault();
            fillSilentCaptcha();
            form.unbind('submit').submit();
        });
    }
});

window.rot13 = function(message)
{
    return message.replace(/[a-z]/gi, letter => String.fromCharCode(letter.charCodeAt(0) + (letter.toLowerCase() <= 'm' ? 13 : -13)));
}

window.base64_decode = function base64_decode(e)
{
    var t="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";var n,r,i,s,o,u,a,f,l=0,c=0,h="",p=[];if(!e){return e}e+="";do{s=t.indexOf(e.charAt(l++));o=t.indexOf(e.charAt(l++));u=t.indexOf(e.charAt(l++));a=t.indexOf(e.charAt(l++));f=s<<18|o<<12|u<<6|a;n=f>>16&255;r=f>>8&255;i=f&255;if(u==64){p[c++]=String.fromCharCode(n)}else if(a==64){p[c++]=String.fromCharCode(n,r)}else{p[c++]=String.fromCharCode(n,r,i)}}while(l<e.length);h=p.join("");return h.replace(/\0+$/,"")
};
window.base64_encode = function base64_encode (stringToEncode) {
    var encodeUTF8string = function (str) {
        return encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes (match, p1) {
                return String.fromCharCode('0x' + p1)
            })
    }
    if (typeof window !== 'undefined') {
        if (typeof window.btoa !== 'undefined') {
            return window.btoa(encodeUTF8string(stringToEncode))
        }
    } else {
        return new Buffer(stringToEncode).toString('base64')
    }
    var b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
    var o1
    var o2
    var o3
    var h1
    var h2
    var h3
    var h4
    var bits
    var i = 0
    var ac = 0
    var enc = ''
    var tmpArr = []
    if (!stringToEncode) {
        return stringToEncode
    }
    stringToEncode = encodeUTF8string(stringToEncode)
    do {
        // pack three octets into four hexets
        o1 = stringToEncode.charCodeAt(i++)
        o2 = stringToEncode.charCodeAt(i++)
        o3 = stringToEncode.charCodeAt(i++)

        bits = o1 << 16 | o2 << 8 | o3

        h1 = bits >> 18 & 0x3f
        h2 = bits >> 12 & 0x3f
        h3 = bits >> 6 & 0x3f
        h4 = bits & 0x3f

        // use hexets to index into b64, and append result to encoded string
        tmpArr[ac++] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4)
    } while (i < stringToEncode.length)

    enc = tmpArr.join('')

    var r = stringToEncode.length % 3

    return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3)
}

