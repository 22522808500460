$(document).ready(function () {

    let nl_form_send = 0;
    $(document).on('submit', 'form#newsletter-subscription', function(e) {
        e.preventDefault();
        var form = $(this);
        form.find('.invalid-feedback').remove();
        form.find('.alert-success').remove();

        if(nl_form_send === 1)
        {
            return false;
        }
        nl_form_send = 1;

        $.ajax({
            url: appUrl()+'/newsletter/anmelden',
            type: 'post',
            data: $(this).serialize(),
            success: function(response) {
                form.find('.newsletter-response').html('<div class="alert alert-success alert-dismissible fade show mb-5 w-90" style="margin-top: 10px;" role="alert">'+response.success+'<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>');
                form.find('.alert-success').delay(10000).fadeOut();
                nl_form_send = 0;
            },
            error: function(error) {
                var errors = error.responseJSON.errors;

                var element = form.find('input[name="email"]');
                if (element.hasClass('homescreen')) {
                    element = element.parent();
                }

                if (errors.email) {
                    element.before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+errors.email[0]+'</strong></span>');
                }

                if (errors.challenge)
                {
                    element.before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+errors.challenge[0]+'</strong></span>');
                }

                if (errors.privacy_accepted) {
                    element.before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+errors.privacy_accepted[0]+'</strong></span>');
                }
                nl_form_send = 0;
                //reCaptcha();
            }
        });
    });
});
