$(document).ready(function () {

    if ($('#recommendation-search-table').length) {
        var isBackofficeDashboard = $('#recommendation-search-table').data('dir') == 'backoffice' && $('#recommendation-search-table').data('dashboard');

        $('#recommendation-search-table').DataTable({
            "processing": false,
            scrollX: true,
            "serverSide": true,
            "ajax": {
                "url": $('#recommendation-search-table').data('route'),
                "dataType": "json",
                "type": "post",
                "cache": false
            },
            "columns": [
                {"data": "date"},
                {"data": "advertise_user_first_name"},
                {"data": "email"},
                {"data": "first_name"},
                {"data": "last_name"},
                {"data": "status_id"},
                {"data": "id"},
            ],
            "columnDefs": [
                {
                    "targets": 1,
                    "className": !isBackofficeDashboard ? "d-none" : "",
                    "createdCell": function (td, cellData, rowData, row, col) {
                        if (isBackofficeDashboard) {
                            $(td).html('<a class="text-link" href="' + appUrl() + '/backoffice/kundenliste/details/' + rowData.advertise_user_id + '">' + rowData.advertise_user_first_name + '</a>');
                        }
                    }
                },
                {
                    "targets": 5,
                    "createdCell": function (td, cellData, rowData, row, col) {
                        if (rowData.status_id == 1) {
                            $(td).html('Freund geworben (offen)');
                        }
                        if (rowData.status_id == 2) {
                            $(td).html('Freund geworben (bestätigt)');
                        }
                        if (rowData.status_id == 3) {
                            $(td).html('Auszahlung steht an');
                        }
                        if (rowData.status_id == 4) {
                            $(td).html('Prämie per IBAN ausgezahlt');
                        }
                        if (rowData.status_id == 5) {
                            $(td).html('Prämie per PayPal ausgezahlt');
                        }
                    }
                },
                {
                    "targets": 6,
                    "className": "text-center",
                    "sortable": false,
                    "createdCell": function (td, cellData, rowData, row, col) {
                        $(td).html('');

                        if (rowData.status_id == 1) {

                            if ($('#recommendation-search-table').data('dir') == 'meine-empfehlungen') {
                                $(td).html(
                                    '<button class="readvertise-modal w-100 d-flex justify-content-center align-items-center bg-transparent border-0" ' +
                                            'type="button"' +
                                            'data-bs-toggle="modal"' +
                                            'data-bs-target="#advertiseOpenModal"' +
                                            'data-id="' + rowData.id + '"' +
                                            // 'data-email="' + rowData.email + '"' +
                                            'data-first-name="' + rowData.first_name + '"' +
                                            'title="Empfänger erneut benachrichtigen"' +
                                    '>' +
                                        '<img class="h-5" src="' + appUrl() + '/images/icons/icon-email.png' + '">' +
                                    '</button>'
                                );
                            } else {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-email.png' + '" title="Freund geworben (offen)">'
                                );
                            }

                        }

                        if (rowData.status_id == 2) {
                            if ($('#recommendation-search-table').data('dir') == 'backoffice') {
                                $(td).html(
                                    '<button class="backoffice-submit-advertise-modal w-100 d-flex justify-content-center align-items-center bg-transparent border-0" ' +
                                        'type="button"' +
                                        'data-bs-toggle="modal"' +
                                        'data-bs-target="#advertiseSubmitModal"' +
                                        'data-advertise_id="' + rowData.advertise_id + '"' +
                                        'data-advertise_user_id="' + rowData.advertise_user_id + '"' +
                                        'data-advertised-first-name="' + rowData.first_name + '"' +
                                        'data-advertise-first-name="' + rowData.advertise_user_first_name + '"' +
                                        'title="Durchgeführte Behandlung bestätigen"' +
                                    '>' +
                                        '<img class="h-5" src="' + appUrl() + '/images/icons/icon-email-checked.png' + '">' +
                                    '</button>'
                                );
                            } else {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-email-checked.png' + '" title="Bestätigt">'
                                );
                            }
                        }

                        if (rowData.status_id == 3) {
                            if ($('#recommendation-search-table').data('dir') == 'meine-empfehlungen') {
                                $(td).html(
                                    '<form method="post" action="' + appUrl() + '/auszahlung?t=' + rowData.advertise_id + '" novalidate>' +
                                    '<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '">' +
                                    '<button class="w-100 d-flex justify-content-center align-items-center bg-transparent border-0" ' +
                                    'type="submit"' +
                                    'title="Zur Auszahlung bringen"' +
                                    '>' +
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-cash.png' + '">' +
                                    '</button>'
                                );
                            } else {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-cash.png' + '" title="Auszahlung steht an">'
                                );
                            }
                        }

                        if (rowData.status_id == 4) {
                            if ($('#recommendation-search-table').data('dir') == 'backoffice') {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-iban.png' + '" title="Prämie per IBAN auszahlen">'
                                );
                            } else {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-iban.png' + '" title="Prämie per IBAN ausgezahlt">'
                                );
                            }
                        }

                        if (rowData.status_id == 5) {
                            if ($('#recommendation-search-table').data('dir') == 'backoffice') {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-paypal.png' + '" title="Prämie per PayPal auszahlen">'
                                );
                            } else {
                                $(td).html(
                                    '<img class="h-5" src="' + appUrl() + '/images/icons/icon-paypal.png' + '" title="Prämie per PayPal ausgezahlt">'
                                );
                            }
                        }
                    }
                },
            ],
            "order": [[0, "desc"]],
            "searching": false,
            "lengthChange": false,
            "language": {
                "url": appUrl() + "/i18n/dataTables.de.lang.json",
                // "emptyTable": 'Zurzeit haben Sie leider keine Freunde geworben. Jetzt Freunde werben und von der satten <b>150 Euro Prämie</b> profitieren.',
                "emptyTable": 'Keine Einträge gefunden.',
            },
            "initComplete": function (settings, json) {
                $('#recommendation-search-table').show();
                $('#recommendation-search-table').DataTable().columns.adjust().draw(false);
            }
        });
    }

    $(document).on('click', '.readvertise-modal', function() {
        $($(this).data('bs-target')).find('.advertise-first-name').text($(this).data('first-name'));
        $($(this).data('bs-target')).find('.readvertise').attr('data-id', $(this).data('id'));
    });

    $(document).on('click', '.readvertise', function(e) {
        e.preventDefault();

        $.ajax({
            type: 'post',
            url: appUrl() + '/freunde-werben',
            cache: false,
            data: {
                id: $(this).data('id'),
                from_recommendation: 1
            },
            success: function (data) {
                window.location.href = window.location.href;
            },
        });
    });

    $(document).on('mousedown', '.advertise-send', function(e) {
        var subject = $(document).find('title').first().html() + ' - Empfehlung von ' + $(this).parents('.form').find('#fromName').val();
        var body = $(this).parents('.form').find('#emailMsg').text();
        body = body.replace(/\n/g,"%0D%0A");
        // var start = '/zustimmen/';
        // var end = '%0D%0A%0D%0AViele Grüße';
        // var myString = body.substr(body.indexOf(start) + start.length, body.indexOf(end) - (body.indexOf(start) + start.length));
        // body = body.substring(0, body.indexOf(start) + start.length) + myString + body.substring(body.indexOf(end));

        $(this).attr('href',
            'mailto:' + $(this).parents('.form').find('input[name="email"]').val() +
            '?subject=' + subject +
            '&body=' + body
        );
    });

    $(document).on('click', '.generate-advertise-link', function() {
        var form = $(this).parents('#advertisePost');
        if (form.length && !form.find('#generateAdvertiseLink').length) {
            form.append('<input type="hidden" id="generateAdvertiseLink" value="1">');
        }
        form.submit();
    });

    $(document).on('change', '#advertiseOption', function(e) {
        var form = $('#advertisePost');
        var email = form.find('input[name="email"]');
        var first_name = form.find('input[name="first_name"]');
        var last_name = form.find('input[name="last_name"]');
        var phone = form.find('input[name="phone"]');
        var message = form.find('textarea[name="message"]');
        var link = form.find('textarea[name="link"]');
        var submit = form.find('button[type="submit"]');

        if ($(this).val() == 1) {
            $('#advertiseOptionSystemAlert').removeClass('d-none');
            if (!$('#advertiseOptionMailAlert').hasClass('d-none')) {
                $('#advertiseOptionMailAlert').addClass('d-none');
            }

            email.attr('required', 'required');
            if (!email.parents('.form__grup').first().hasClass('required')) {
                email.parents('.form__grup').first().addClass('required');
            }
            first_name.attr('required', 'required');
            if (!first_name.parents('.form__grup').first().hasClass('required')) {
                first_name.parents('.form__grup').first().addClass('required');
            }
            last_name.attr('required', 'required');
            if (!last_name.parents('.form__grup').first().hasClass('required')) {
                last_name.parents('.form__grup').first().addClass('required');
            }
            phone.attr('required', 'required');
            if (!phone.parents('.form__grup').first().hasClass('required')) {
                phone.parents('.form__grup').first().addClass('required');
            }
            message.parent().removeClass('d-none');
            if (!link.parent().hasClass('d-none')) {
                link.parent().addClass('d-none');
            }
            submit.text('Versenden');
            form.find('.info-col').first().removeClass('d-none');
        } else {
            $('#advertiseOptionMailAlert').removeClass('d-none');
            if (!$('#advertiseOptionSystemAlert').hasClass('d-none')) {
                $('#advertiseOptionSystemAlert').addClass('d-none');
            }

            email.removeAttr('required');
            email.parents('.form__grup').first().removeClass('required');
            first_name.removeAttr('required');
            first_name.parents('.form__grup').first().removeClass('required');
            last_name.removeAttr('required');
            last_name.parents('.form__grup').first().removeClass('required');
            phone.removeAttr('required');
            phone.parents('.form__grup').first().removeClass('required');
            if (!message.parent().hasClass('d-none')) {
                message.parent().addClass('d-none');
            }
            link.parent().removeClass('d-none');
            submit.text('Link erzeugen');
            if (!form.find('.info-col').first().hasClass('d-none')) {
                form.find('.info-col').first().addClass('d-none');
            }
        }
    });

    $(document).on('submit', '#advertisePost', function(e) {
        e.preventDefault();

        var form = $(this);
        var option = $(this).find('select[name="option"]').val();
        var email = $(this).find('input[name="email"]').val();
        var first_name = $(this).find('input[name="first_name"]').val();
        var last_name = $(this).find('input[name="last_name"]').val();
        var phone = $(this).find('input[name="phone"]').val();
        var message = $(this).find('textarea[name="message"]').val();

        form.find('span.invalid-feedback')
            .remove();

        $.ajax({
            type: 'post',
            url: appUrl() + '/freunde-werben',
            cache: false,
            data: {
                option: option,
                email: email,
                first_name: first_name,
                last_name: last_name,
                phone: phone,
                message: message
            },
            success: function(data) {
                if (!data.sent) {
                    var msg = data.msg;
                    msg = msg.replace('&amp;', '&');
                    msg = decodeEntities(msg);
                    msg = msg.replaceAll('&', '%26');
                    msg = msg.replaceAll('+', '%2B');

                    form.find('input[name="email"]').val(data.email);
                    form.find('input[name="first_name"]').val(data.first_name);
                    form.find('input[name="last_name"]').val(data.last_name);
                    form.find('input[name="phone"]').val(data.phone);
                    form.find('#emailMsg').html(msg);
                    form.find('textarea[name="link"]').val(data.link);
                    form.find('.hidden-textarea').removeClass('d-none');
                    if ($(document).find('.alert-info').length && !$(document).find('.advertise-info.alert-copied').length) {
                        $(document).find('.alert-info').last().after(data.alert);
                    }
                    if (form.find('select[name="option"]').val() == 2) {
                        form.find('.advertise-send').trigger('mousedown');
                        form.find('.advertise-send').click();
                    }
                } else {
                    form.find('input[name="email"]').val('');
                    form.find('input[name="first_name"]').val('');
                    form.find('input[name="last_name"]').val('');
                    form.find('input[name="phone"]').val('');
                    form.find('textarea[name="message"]').val('');
                    window.location.href = window.location.href;
                }
            },
            error: function(data) {

                switch (data.status) {
                    case 419:
                        form.find('input[name="email"]')
                            .parent()
                            .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>Ihre Session ist abgelaufen. Bitte laden Sie die Seite neu.</strong></span>');
                        break;
                    default:
                        if (data.responseJSON.errors.email) {

                            form.find('input[name="email"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.email[0]+'</strong></span>');
                        }
                        if (data.responseJSON.errors.first_name) {

                            form.find('input[name="first_name"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.first_name[0]+'</strong></span>');
                        }
                        break;
                }
            }
        });
    });

    $(document).on('change', '#payoutOption', function(e) {
        var form = $('#payoutPost');
        var email = form.find('input[name="email"]');
        var last_name = form.find('input[name="last_name"]');
        var iban = form.find('input[name="iban"]');

        if ($(this).val() == 1) {
            email.parents('.form__grup').first().removeClass('d-none');
            if (!last_name.parents('.form__grup').first().hasClass('d-none')) {
                last_name.parents('.form__grup').first().addClass('d-none');
            }
            if (!iban.parents('.form__grup').first().hasClass('d-none')) {
                iban.parents('.form__grup').first().addClass('d-none');
            }
        } else {
            if (!email.parents('.form__grup').first().hasClass('d-none')) {
                email.parents('.form__grup').first().addClass('d-none');
            }
            last_name.parents('.form__grup').first().removeClass('d-none');
            iban.parents('.form__grup').first().removeClass('d-none');
        }

        form.find('span.invalid-feedback')
            .remove();
    });

    $(document).on('submit', '#payoutPost', function(e) {
        e.preventDefault();

        var form = $(this);
        var option = $(this).find('select[name="option"]').val();
        var first_name = $(this).find('input[name="first_name"]').val();
        var email = $(this).find('input[name="email"]').val();
        var last_name = $(this).find('input[name="last_name"]').val();
        var iban = $(this).find('input[name="iban"]').val();

        var data = {};
        data['option'] = option;
        data['first_name'] = first_name;

        if (option == 1) {
            data['email'] = email;
        } else {
            data['last_name'] = last_name;
            data['iban'] = iban;
        }

        if ($(this).find('input[name="target"]').length) {
            data['target'] = $(this).find('input[name="target"]').val();
        }

        form.find('span.invalid-feedback')
            .remove();

        $.ajax({
            type: 'post',
            url: appUrl() + '/auszahlung',
            cache: false,
            data: data,
            success: function(data) {
                form.find('input[name="first_name"]').val('');
                form.find('input[name="email"]').val('');
                form.find('input[name="last_name"]').val('');
                form.find('input[name="iban"]').val('');
                window.location.href = data.redirect;
            },
            error: function(data) {

                switch (data.status) {
                    case 419:
                        form.find('input[name="email"]')
                            .parent()
                            .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>Ihre Session ist abgelaufen. Bitte laden Sie die Seite neu.</strong></span>');
                        break;
                    default:
                        if (data.responseJSON.errors.first_name) {

                            form.find('input[name="first_name"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.first_name[0]+'</strong></span>');
                        }
                        if (data.responseJSON.errors.email) {

                            form.find('input[name="email"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.email[0]+'</strong></span>');
                        }
                        if (data.responseJSON.errors.last_name) {

                            form.find('input[name="last_name"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.last_name[0]+'</strong></span>');
                        }
                        if (data.responseJSON.errors.iban) {

                            form.find('input[name="iban"]')
                                .parent()
                                .before('<span class="invalid-feedback d-block label-up" role="alert"><strong>'+data.responseJSON.errors.iban[0]+'</strong></span>');
                        }
                        break;
                }
            }
        });
    });

    function decodeEntities(encodedString) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    }

    $('#payout-search-table').DataTable({
        "processing": false,
        scrollX: true,
        "serverSide": true,
        "ajax": {
            "url": $('#payout-search-table').data('route'),
            "dataType": "json",
            "type": "post",
            "cache": false
        },
        "columns": [
            {"data": "date"},
            // {"data": "id"},
            {"data": "type"},
            {"data": "decrypted_email"},
            {"data": "decrypted_first_name"},
            {"data": "decrypted_last_name"},
            {"data": "decrypted_iban"},
            {"data": "status_id"},
            {"data": "id"},
        ],
        "columnDefs": [
            {
                "targets": [1,2,3,4],
                "sortable": false
            },
            {
                "targets": [3,4],
                "className": $('#payout-search-table').data('dir') !== 'backoffice' ? 'd-none' : ''
            },
            {
                "targets": 5,
                "className": "text-sensible",
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html(rowData.decrypted_iban.substr(0, rowData.decrypted_iban.length - 3).replace(/[\S]/g, "*") + rowData.decrypted_iban.substr(rowData.decrypted_iban.length - 3));
                },
                "sortable": false
            },
            {
                "targets": 6,
                "className": "text-center",
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html('');

                    if (rowData.status_id == 1) {
                        $(td).html('Offen');
                    }

                    if (rowData.status_id == 2) {
                        $(td).html('<i class="fa me-3 fa-check" title="Ausgezahlt"></i>Ausgezahlt');
                    }
                },
                "sortable": false
            },
            {
                "targets": 7,
                "className": "text-center",
                "createdCell": function (td, cellData, rowData, row, col) {
                    $(td).html('');

                    if (rowData.status_id == 2) {
                        $(td).html('am <span class="text-nowrap">' + rowData.updated + '</span>');
                    }

                    if ($('#payout-search-table').data('dir') == 'backoffice') {
                        if (rowData.status_id == 1) {
                            $(td).html(
                                '<button class="complete-payout w-100 d-flex justify-content-center align-items-center bg-transparent border-0 text-blue" ' +
                                'type="button"' +
                                'data-bs-toggle="modal"' +
                                'data-bs-target="#payoutCompleteModal"' +
                                'data-id="' + rowData.id + '"' +
                                'data-payout_id="' + rowData.user_advertise_payout_id + '"' +
                                'data-first-name="' + rowData.first_name + '"' +
                                'title="Klicken, um auf Ausgezahlt zu setzen."' +
                                '>' +
                                'Ausgezahlt' +
                                '</button>'
                            );
                        }
                    }
                },
                "sortable": false
            },
        ],
        "order": [[0, "desc"]],
        "searching": false,
        "lengthChange": false,
        "language": {
            "url": appUrl() + "/i18n/dataTables.de.lang.json",
            "emptyTable": 'Keine Einträge gefunden.',
        },
        // "preInit": function () {
        //     $('#completeButtonWrapper').html('');
        // },
        "initComplete": function (settings, json) {
            $('#payout-search-table').show();
            $('#payout-search-table').DataTable().columns.adjust().draw(false);

            if ($('#completeButtonWrapper').length) {
                $('#completeButtonWrapper').html('');

                $.ajax({
                    type: 'get',
                    // url: appUrl() + '/backoffice/kundenliste/details/' + $('#payout-search-table').data('id') + '/auszahlungen/bestaetigen/button',
                    url: $('#payout-search-table').data('button'),
                    cache: false,
                    success: function (data) {
                        if (data.success) {
                            $('#completeButtonWrapper').html(data.view);
                        }
                    },
                });
            }
        }
    });
});
