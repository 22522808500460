$(document).ready(function () {

    $(document).on('click', '#cookie-consent .btn-accept', acceptCookies);
    $(document).on('click', '#cookie-consent .btn-accept-all', acceptAllCookies);
    $(document).on('click', '#cookie-consent .btn-settings', showSettings);
    $(document).on('click', 'a.cookie-preferences', editSettings)

    if ($(document).find('#cookie-consent').length > 0) {
        showCookieModal();
    }

    function showCookieModal() {
        let cookieConsentModal = new bootstrap.Modal(document.getElementById('cookie-consent'), {
            backdrop: 'static',
            keyboard: false
        })

        cookieConsentModal.show();
    }

    function hideCookieModal() {
        bootstrap.Modal.getInstance(document.getElementById('cookie-consent')).hide();
    }

    function showSettings() {
        $(document).find('#cookie-consent .toggle-content').toggleClass('d-none');
    }

    function acceptCookies() {
        let data = {};
        $.each($(document).find('#cookie-consent .settings input'), function (key, value) {
            let name = $(value).attr('name');
            data[name] = $(value).is(':checked');
        });

        $.ajax({
            url: appUrl() + '/cookie-consent/accept',
            method: 'post',
            data: data,
            success: function (response) {
                if (response.hasOwnProperty('success') && response.success) {
                    hideCookieModal();
                    location.reload();
                }
            },
            error: function (error) {},
        });
    }

    function acceptAllCookies() {
        $.ajax({
            url: appUrl() + '/cookie-consent/accept/all',
            method: 'post',
            success: function (response) {
                if (response.hasOwnProperty('success') && response.success) {
                    hideCookieModal();
                    location.reload();
                }
            },
            error: function (error) {},
        });
    }

    function editSettings(e) {
        e.preventDefault();

        $.ajax({
            url: appUrl() + '/cookie-consent/edit',
            method: 'post',
            success: function (response) {
                if (response.hasOwnProperty('success') && response.success) {
                    if ($(document).find('#cookie-consent').length <= 0) {
                        $('body').append(response.view);
                        showCookieModal();
                    }
                }
            },
            error: function (error) {},
        });
    }
});
