require('./bootstrap');

// import Dropzone from 'dropzone';
// Dropzone.autoDiscover = false;
import BeerSlider from 'beerslider/dist/BeerSlider';
import ClipboardJS from "clipboard";

// require('./datepicker_de');
require('./mediacare/implement');
require('./datepicker_de');
require('./silentCaptcha');
require('./login');
require('./newsletter');
require('./user');
require('./cookie-consent');

window.appUrl = function appUrl() {
    let appUrl = document.head.querySelector('meta[name="app-url"]');
    return appUrl ? appUrl.content : '';
};

$(document).ready(function(){
    $(".tabs-content").eq(0).show();
    $("#tabs li").eq(0).addClass("button-renk");
    $("#tabs li").click(function(){
        var number = $(this).index();
        $("#tabs li").removeClass("button-renk");
        $(".tabs-content").hide().eq(number).fadeIn("slow");
        $("#tabs li").eq(number).addClass("button-renk");
    });

    $(document).on('click', '.logout-trigger', function(e) {
        e.preventDefault();
        $('#logout-form').submit();
    });

    if ($('div.alert-error').length || $('.invalid-feedback.d-block').length) {
        let scrollTop = 0;
        let scrollTop2 = 0;
        let parent;
        if($('div.alert-error').length) {
            parent = $('div.alert-error').first().parents('section').first();
            scrollTop = (parent.offset().top + parseFloat(parent.css('padding-top').replace('px', '')) - 120);
        }
        if($('.invalid-feedback.d-block').length) {
            parent = $('.invalid-feedback.d-block').last().parents('section').first();
            scrollTop2 = (parent.offset().top + parseFloat(parent.css('padding-top').replace('px', '')) - 120);
            if (!scrollTop || ( scrollTop2 && scrollTop2 < scrollTop )){
                scrollTop = scrollTop2;
            }
        }
        $('html').stop().animate({
            'scrollTop': scrollTop
        }, 500);
    }

    $(document).on('keyup', 'input[name="code"]', function (e) {
        replaceCode(e);
    });
    $(document).on('change', 'input[name="code"]', function (e) {
        replaceCode(e);
    });

    function replaceCode(e) {
        var strlen =  e.target.value.replace(/[-]/g, "").length;
        var minuslen = e.target.value.replace(/[^-]/g, "").length;
        e.target.value = e.target.value.replace(/[^\da-zA-Z-]/g, '').trim().toUpperCase();
        if (strlen / (minuslen + 1)  > 4) {
            e.target.value = e.target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1-').trim().toUpperCase();
        }
    }

    $(window).on('resize', function() {
        resizeTeaser();
    });
    resizeTeaser();

    $(document).on('keyup', 'input[name="iban"]', function (e) {
        e.target.value = e.target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();
    });

    $(document).on('change', 'input[name="iban"]', function (e) {
        e.target.value = e.target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();
    });

    $(document).find('#faq').on('show.bs.collapse', '.collapse', function () {
        $(document).find('#faq h3').css('font-weight', 400);
        $(this).prev('h3').css('font-weight', 'bold');
        $(document).find('#faq .collapse.show').collapse('hide');
    });
    $(document).find('#faq').on('hide.bs.collapse', '.collapse', function () {
        $(this).prev('h3').css('font-weight', 400);
    });

    var observe;
    $(document).on('keyup', '.observable', function (e) {
        if (!$(this).hasClass('dirty')) {
            $(this).addClass('dirty');
        }
    });
    $(document).on('change', '.observable', function (e) {
        if (!$(this).hasClass('dirty')) {
            $(this).addClass('dirty');
        }
    });
    $(document).on('mousedown', '.observe:not(.observed)', function(e) {
        if ($('.observable.dirty:visible').length) {
            observe = $(this);
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            // if (!$(this).hasClass('observed')) {
            //     $(this).addClass('observed');
            // }
            if ($('form.customer-form:visible').find('.response_target').length) {
                $('form.customer-form:visible').find('.response_target').val($(this).data('bs-target'));
            } else {
                $('form.customer-form:visible').append('<input type="hidden" name="response_target" class="response_target" value="' + $(this).data('bs-target') + '">');
            }
            $('.observable-modal-trigger').click();
        }
    });
    $(document).on('click', '.observed', function() {
        $(this).removeClass('observed');
    });
    $(document).on('click', '.observable-modal .save', function() {
        $('form.customer-form:visible').submit();
    });
    $(document).on('hide.bs.modal', '.observable-modal', function (e) {
        if (!observe.hasClass('observed')) {
            observe.addClass('observed');
        }
        observe.click();
        observe.tab('show');
    });
});

$(window).on('load', function() {

    // init_pointer({
    //
    // })

    $(function() {
        $("#top").click(function() {
            $("html,body").stop().animate({ scrollTop: "0" }, 100);
        });
    });

    $(window).scroll(function() {
        var uzunluk = $(document).scrollTop();
        if (uzunluk > 300) $("#top").fadeIn(500);
        else { $("#top").fadeOut(500); }
    });

    $.fn.BeerSlider = function ( options ) {
        options = options || {};
        return this.each(function() {
            new BeerSlider(this, options);
        });
    };
    $('.beer-slider').BeerSlider({start: 50});

    // PRELOADER
    $("body").addClass("page-loaded");
    resizeTeaser();
    initClipboard();
});

window.resizeTeaser = function resizeTeaser() {
    var width = $(window).width();
    if (width > 767) {
        $('.swiper-slide').each(function() {
            $(this).attr('data-background', $(this).data('desktop'));
            $(this).data('background', $(this).data('desktop'));

            if ($(this).attr("data-background")){
                $(this).css("background-image", "url(" + $(this).data("background") + ")");
                $(this).css("height", "calc(100vw / 2.23255814)");
            }
        });
    } else {
        $('.swiper-slide').each(function() {
            $(this).attr('data-background', $(this).data('mobile'));
            $(this).data('background', $(this).data('mobile'));

            if ($(this).attr("data-background")){
                $(this).css("background-image", "url(" + $(this).data("background") + ")");
                $(this).css("height", "calc(100vw / 0.8333333)");
            }
        });
    }
}

window.initClipboard = function initClipboard() {
    var clipboard = new ClipboardJS('.copy-btn');

    clipboard.on('success', function(e) {
        if ($(document).find('.alert-info').length && !$(document).find('.advertise-info.copied').length) {
            var html = $(document).find('.alert-info').last().clone();
            html.find('span').first().html('Erfolgreich in Zwischenablage kopiert.');
            html.addClass('copied');
            html.removeClass('alert-copied');
            $(document).find('.alert-info').last().after(html);

        }
        e.clearSelection();
    });

    clipboard.on('error', function(e) {
    });
}
